
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$option-border: 1px solid rgba(0, 0, 0, 0.12);

.container {
  align-items: center;
  display: flex;
  flex-flow: wrap;
  margin: 18px 0 0;
  @include mobile {
    flex-flow: column;
    align-items: baseline;
  }
}

.text {
  margin:0 $gl-spacer-xs 0 0;
  font-size: $gl-font-size-xs;
  line-height: 1.5;
  color: rgba($theme-light-lt, 0.61);
  align-items: center;
  display: flex;

  &__icon {
    color: rgba($theme-light-lt, 0.25);
    margin-right: $gl-spacer-xs;
  }
}
.text--dark {
  color: rgba(0, 0, 0, 0.61);
}
.text__icon--dark {
  color: rgba(0, 0, 0, 0.12);
}


.inner {
  display: flex;
  flex-flow: wrap;
  @include mobile {
    margin-top: $gl-spacer-xxs;
    overflow: scroll;
    position: relative;
    width: 100%;
    flex-flow: row;
  }

  .tag {
    background: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.93);
    font-weight: 400;
    font-size: $gl-font-size-xxs;
    padding: 4px 8px;
    line-height: 1;
    display: inline-flex;
    align-items: center;

    &__inner {
      display: inline-block;
      max-width: 156px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      @include media('<xxl') {
        max-width: 147px;
      }
      @include media('<=1140px') {
        max-width: 110px;
      }
    }

    &__icon {
      color: rgba($theme-light-lt, 0.25);
      margin-right: 4px;
    }

    &:hover {
      background-color: rgba($theme-light-lt,0.15);
      color: $theme-light-lt;
    }
  }

  .tag + .tag {
    margin-left: 8px;
  }

  .tag--dark {
    background: #F5F5F5;
  }

  .tag__icon--dark {
    color: rgba(0, 0, 0, 0.69);
  }

  .tag__inner--dark {
    color: rgba(0, 0, 0, 0.69);
  }
}
