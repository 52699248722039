
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$arrow-color: #514E6E;

.wrapper {
  padding: $gl-spacer-s $gl-spacer-m;
  overflow-x: auto;

  @include mobile {
    padding-bottom: 20px;
  }
}

// fix container width on text bolding
.container {
  @include list-unstyled;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  @include desktop {
    justify-content: center;
  }

  .tab {
    position: relative;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;

    &__link {
      @include button-reset;
      display: flex;
      align-items: center;
      font-weight: 500;
      color: rgba($theme-dark-dkr, 0.69);
      line-height: 1.25;

      &--active {
        color: $theme-dark-dk;
        font-weight: 600;
      }

      @include hover {
        color: $theme-dark-dk;
      }
    }
  }

  .tab + .tab {
    margin-left: 32px;
    @include mobile {
      margin-left: $gl-spacer-m;
    }
  }
}

.item__underline {
  background: $theme-primary;
  border-radius: 100px;
  bottom: -8px;
  height: 3px;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  width: 12px;
}

.item__underline_arrow {
  width: 20px;
  height: 20px;
  background: $arrow-color;
  transform: rotate(45deg);
  border-radius: 2px;
  top:10px;
  position: absolute;
}

.item__arrow_cover {
  position: absolute;
  height: 17px;
  width: 20px;
  overflow: hidden;

  bottom: -26px;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  transform: scaleX(1.1);
}

.count {
  display: inline-flex;
  padding: 0px 8px;
  background: #F5F5F5;
  border-radius: 10px;
  margin-left: $gl-spacer-3xs;
  font-size: 10px;
  line-height: 1.5;
  font-weight: normal;

  &--active {
    font-weight: 600;
    /* identical to box height, or 15px */

    text-align: center;
    color: $theme-primary;
  }
}
