
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$padding-x: $gl-spacer-m;

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: $theme-light-lt;
  z-index: $gl-z-index-modal;
  padding: $gl-spacer-m 0;
  display: flex;
  flex-direction: column;
  // padding-bottom: 70px;
}

.section {
  padding-left: $padding-x;
  padding-right: $padding-x;
}

.tabs {
  // padding-bottom: $gl-spacer-s;
  margin-bottom: $gl-spacer-m;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.top {
  padding-bottom: 18px;
  text-align: left;
  flex: 0 0 auto;
}

.inner {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  position: relative;
}

.options {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.close {
  @include button-reset();
  cursor: pointer;
  font-size: $gl-font-size-xs;
  font-weight: 500;
  color: inherit;
  margin-left: -8px;
}


.form {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  // margin-bottom: $gl-spacer-xs;

  &__field {
    border: 1px solid rgba(0, 0, 0, 0.12);

    &:first-child {
      @include border-top-radius(12px)
    }

    &:last-child {
      @include border-bottom-radius(12px)
    }

    &:not(.single) .inner {
      background: none;
      border:none;
    }
  }

  &__field + &__field {
    border-top: none;
  }

  .single {
    border: 1px solid $theme-primary;
    border-radius: 100px;
    padding-left: $gl-spacer-xs;
  }


}

.footer {
  padding: $gl-spacer-xs $gl-spacer-m;
  border-top: 1px solid rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 auto;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: $theme-light-lt;
}

.select {
  &__wrapper {
    background: none;
    border: none;
  }
}

.tabs_row {
  display: flex;
  overflow-x: auto;
  padding-bottom: $gl-spacer-s;
}
