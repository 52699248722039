/* ==========================================================================
   #TAG MIXINS
   ========================================================================== */

// Tag variants
@mixin tag-variant(
  $background,
  $color,
  $important: null
) {
  & {
    background-color: $background $important;
    color: $color $important;
  }
}
