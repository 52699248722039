
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$wrapper-size: calc(100% - #{$gl-spacer-l * 2});

div.wrapper {
  overflow: unset;

  @include desktop {
    @include size($wrapper-size, $wrapper-size);

    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  div.wrapper__inner {
    @include desktop {
      flex: 1;
    }

    @include mobile {
      border-radius: 0;
      max-height: 100%;
      padding: 0;
    }
  }
}
