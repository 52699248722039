
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  @include button-reset();

  display: inline-block;
  color: $theme-dark-dkr;
  cursor: pointer;
}

.svg {
  @include size(24px);
}

@include desktop {
  .container {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @include size(48px);
    border-radius: 50%;
    border: 1px solid rgba($theme-dark-dkr, 0.12);

    @include hover-focus {
      background-color: $theme-dark-ltr;
    }
  }

  .svg {
    @include size(26px);
  }
}
