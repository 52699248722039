
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.icon {
  color: rgba($theme-dark-dkr, 0.35);
  margin-right: 13px;
}

.container {
  display: flex;
  padding: $gl-spacer-xs $gl-spacer-l;
  color: $theme-dark-dkr;

  &:has(> .meta) {
    min-height: 56px;
  }
}

.content {
  flex: 1;

}

.label {
  color: inherit;
  font-size: $gl-font-size-s2;
  font-weight: 500;
}

.info {
  opacity: 0.61;
  font-size: $gl-font-size-3xs;
  margin-top: 6px;
}

.meta {
  color: $theme-primary;
  font-size: $gl-font-size-xs;
  font-weight: 500;
  margin-top: 7px;
}


.container:hover,
.container.highlighted {
  background: $theme-primary;
  color: $theme-light-lt;

  .icon {
    color: rgba(255, 255, 255, 0.54);
  }

  .meta {
    color: rgba(255, 255, 255, 0.93)
  }
}
