
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$border: 1px solid $clr-light-blue;

* + .wrapper {
  margin-top: $gl-spacer-xxs;
}

.bg {
  background: $theme-light-lt;

  @include desktop {
    border: $border;
    border-radius: 4px;

    &.dark {
      background: rgba($theme-light-lt, 0.8);
    }
  }

  &.dark-dk {
    background: rgba($theme-light-lt, 0.6);
  }
}


.inner {
  padding: $gl-spacer-m;
}

.no-paddings {
  @include mobile {
    padding: 0;
  }
}

.title + .inner {
  padding-top: 0;
}

.title {
  padding: $gl-spacer-m;
  font-size: $gl-font-size-xxs;
  font-weight: 700;
  color: $theme-dark-dk;
  margin-bottom: 0;
  text-transform: uppercase;
}

.bordered-title {
  border-bottom: $border;
}

.bordered,
.inner + .inner,
.inner + .title,
.border-top {
  border-top: $border;
}
