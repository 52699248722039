
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$animationDuration: 250ms;
$translateY: 120%;

.container {
  z-index: $gl-z-index-max;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // transition: transform 0.3s ease-in-out;
  // padding-bottom: 10px;

  &.is-static {
    position: static;
  }

  &.is-amp {
    position: relative;
  }
}

.bottom {
  top: auto;
  bottom: 0;
}


.navbar-slide-top-enter {
  transform: none;
}

.navbar-slide-top-enter-active {
  transition: all $animationDuration ease-out;
  transform: translateY(-$translateY);
}

.navbar-slide-top-enter-done {
  transform: translateY(-$translateY);
  opacity: 0;
}

.navbar-slide-top-exit {
  transform: translateY(-$translateY);
}

.navbar-slide-top-exit-active {
  transition: all $animationDuration ease-out;
  transform: none;
}



.navbar-slide-bottom-enter {
  transform: none;
}

.navbar-slide-bottom-enter-active {
  transition: all $animationDuration ease-out;
  transform: translateY($translateY);
}

.navbar-slide-bottom-enter-done {
  transform: translateY($translateY);
  opacity: 0;
}

.navbar-slide-bottom-exit {
  transform: translateY($translateY);
}

.navbar-slide-bottom-exit-active {
  transition: all $animationDuration ease-out;
  transform: none;
}