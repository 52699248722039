
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  background: $theme-light-lt;
  position: relative;
  z-index: $gl-z-index-pre-max;

  border-radius: 100px;

  .submit {
    margin: 6px;
    font-size: $gl-font-size-s2;
    padding-left: $gl-spacer-l;
    padding-right: $gl-spacer-l;
    cursor: pointer;
  }

  @include mobile {
    background: rgba($theme-light-lt, 0.93);

  }


  .mobile-submit {
    @include button-reset();

    color: rgba(0, 0, 0, 0.69);
    height: 100%;
    padding-left: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
  }

}

.col {
  @include button-reset();

  position: relative;
  font-size: inherit;
  font-weight: inherit;

  & + &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    top: $gl-spacer-xs;
    bottom: $gl-spacer-xs;
    left: 0;
    background: rgba(0,0,0,0.12);
  }


  &.selectable:hover + &::after,
  &.selectable:hover::after {
    display: none;
  }
}



@include desktop {
  .focused {
    background: #F5F5F5;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);

    .col::after {
      display: none;
    }
  }


  .container {

    .submit {
      margin: $gl-spacer-xxs;
    }
  }
}

