
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$dropdown-width: 376px;
$dropdown-max-height: 600px;
$menu-button-hover-bg: rgba($theme-dark-dkr, 0.04);
$search-default-color: $clr-white-dkr;

.navbar-container:not(.loggedout) {
  .desktop-only.desktop-only {
    @include mobile {
      display: none;
    }
  }

  .mobile-only.mobile-only {
    @include desktop {
      display: none;
    }
  }
}

.navbar-container.loggedout {
  .desktop-only.desktop-only {
    @include media('<md') {
      display: none;
    }
  }

  .mobile-only.mobile-only {
    @include media('>=md') {
      display: none;
    }
  }
}


.unfilled-svg {
  fill: none;
}

.amp-overlay {
  background-color: transparent;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $gl-z-index-1;
}

.navbar-container {
  background-color: $theme-light-lt;
  border-bottom: 1px solid #E7EBEA;

  &--search {
    @include mobile {
      border-bottom: none;
      box-shadow: none;

      .navbar {
        height: 56px;
      }
    }
  }
}

.navbar {
  align-items: center;
  display: flex;
  height: $gl-navbar-height-desktop;
  position: relative;

  @include phone {
    padding-left: 24px;
    padding-right: 24px;

  }

  @include mobile {
    height: $gl-navbar-height-mobile;
  }
}

.navbar__logo-large {
  margin-top: -4px;
  width: 110px;

  @include mobile {
    height: 22px;
    width: 99px;
    margin-top: 0;
  }
}

.navbar__logo-small {
  margin-top: -4px;
  margin-left: -4px;

  @include size(26px);

  @include mobile {
    height: 22px;
    width: 22px;
    margin-top: -2px;
  }
}

.navbar__section {
  align-items: center;
  display: flex;

  &--loading {
    visibility: hidden;
  }

  &--right {
    flex-shrink: 0;
    justify-content: flex-end;
  }

  @include phone {
    margin-right: -10px;
  }
}

.navbar__button {
  background: none;
  border-radius: 100px;
  border: none;
  box-shadow: none;
  color: $theme-dark-dkr;
  cursor: pointer;
  font-weight: 600;
  line-height: $gl-line-height-xs;
  outline: none;
  padding: 13px 16px;
  position: relative;
  z-index: $gl-z-index-5;

  &:hover,
  &--active {
    background-color: $menu-button-hover-bg;
  }

  &--signup {
    background-color: $theme-dark-dkr;
    color: $theme-light-lt;
    margin-left: 6px;

    &:hover {
      background-color: tint($theme-dark-dkr, 10%);
    }
  }
}

.navbar__button-badge {
  background-color: #f43c13;
  border-radius: 100px;
  color: $theme-light-lt;
  font-size: $gl-font-size-3xs;
  line-height: 10px;
  padding: 4px 6px;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: $gl-z-index-1;
}

.navbar__link {
  align-items: center;
  border-radius: 100px;
  color: $clr-dark-font;
  display: inline-flex;
  font-size: $gl-font-size-xs;
  font-weight: 600;
  padding: 0 $gl-spacer-s;
  line-height: 40px;
  height: 40px;
  margin-left: -8px;
  white-space: nowrap;

  &:hover,
  &--active {
    background-color: $menu-button-hover-bg;
  }

  &--secondary {
    font-size: $gl-font-size-xxs;
    margin-left: 0px;
    padding: 0 $gl-spacer-xs;
  }

  &--secondary:hover {
    background-color: $clr-white-4;
  }

  &--secondary {
    &__icon {
      color: rgba(0,0,0,0.54);
    }

    &.navbar__link--active,  &:hover {
      background-color: $clr-white-4;
    }
  }

  &--logo {
    white-space: nowrap;
    padding: 0 8px;
    // margin-right: 4px;
    margin-left: -8px;
    margin-right: 24px;

    @include mobile {
      margin-top: -2px;
    }
  }

  svg {
    margin-right: $gl-spacer-4xs;
  }

  // &:last-of-type {
  //   margin-right: -$gl-spacer-xs;
  // }
}

div.navbar__menu-parent {
  // z-index: $gl-z-index-modal - 1;
  z-index: $gl-z-index-max;

  & + & {
    margin-left: $gl-spacer-3xs;
  }

  &--experience-changer {
    width: auto;

    // margin-left: 8px;
    display: flex;
    align-items: center;
    padding: 0;

    button {
      @include hover-focus {
        background-color: $theme-light;
      }
      padding: 0 12px 0 0;
      border-radius: 6px;
      width: auto;
      height: 48px;
    }

    @include phone {
      margin-right: 0;
    }

    svg {
      @include phone {
        width: 20px;
        height: 20px;
      }
    }
  }

  &:not(&--experience-changer) {
    margin-left: 4px;
  }
}

.navbar__menu {
  background: $theme-light-lt;
  border-radius: 16px;
  border: 1px solid rgba($theme-dark-dkr, 0.07);
  box-shadow: 0 4px 20px rgba($theme-dark-dkr, 0.15);
  padding: 28px 0 16px;
  position: absolute;
  top: $gl-navbar-height-desktop - 24;
  width: $dropdown-width;
  z-index: $gl-z-index-5;

  @include mobile {
    top: $gl-navbar-height-mobile - 10;
  }

  &--create {
    right: -$gl-spacer-xs;
    z-index: $gl-z-index-modal;

    @include media('<420px') {
      width: 100vw;
      z-index: $gl-z-index-5;
      right: -$gl-spacer-m;
    }
  }

  &__btn_label {
    margin-left: 6px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: $gl-font-size-s;
    line-height: 1.25;
    color: currentColor;

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    // @include media('<=1200px') {
    //   font-size: $gl-font-size-xs;

    // }

    @include media('<=1142px') {
      display: none;
    }



  }
  &__btn_label--active {
    color: $theme-primary;
  }

  &--experience-changer-items {
    // border-radius: 20px;
    // width: 132px;

    // margin-top: -24px;
    margin-top: -40px;
    // padding-top: 50px;
  }

  &--notifications {
    padding-bottom: 0;
    right: -$gl-spacer-xs;
    max-height: $dropdown-max-height;
    overflow-y: auto;

    .navbar__menu__item {
      padding-bottom: 0;
      padding-top: 0;

      &--view-all {
        border-top: 1px solid rgba($theme-dark-dkr, 0.06);
        color: $theme-primary;
        justify-content: center;
        line-height: 21px;
        padding: 12px 0;
        display: block;
        text-align: center;
      }
    }
  }

  &--pricing {
    padding: 10px 0;
    right: -$gl-spacer-xs;
    z-index: $gl-z-index-modal;
  }

  &--settings {
    padding: 10px 0;
    right: -$gl-spacer-xs;
  }

  &--amp {
    right: 0;
    position: absolute;
    top: $gl-navbar-height-desktop - 24;

    @include mobile {
      top: $gl-navbar-height-mobile - 24;
    }
  }
}

.navbar__menu__button {
  position: relative;
  background: none;
  border-radius: 20px;
  border: none;
  box-shadow: none;
  cursor: pointer;
  height: 40px;
  line-height: 1;
  outline: none;
  padding: 0;
  width: 40px;
  z-index: $gl-z-index-5;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &--active {
    background-color: $menu-button-hover-bg;
  }

  &__icon {
    color: rgba(0,0,0,0.61);

    @include mobile {
      color: $theme-dark-dk;
    }
  }
}

.navbar__menu__icon {
  align-items: center;
  color: $theme-primary;
  background-color: #f1faf8;
  border-radius: 240px;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  margin-right: $gl-spacer-xs;
  justify-content: center;
  width: 40px;

  @include media('<=320px') {
    width: 32px;
    height: 32px;
  }
}

.navbar__menu__item-container {
  list-style: none;
  margin: 0;
  padding: 0;

  .navbar__menu__item {
    @include button-reset();

    align-items: center;
    border-radius: 0;
    color: inherit;
    display: flex;
    font-weight: normal;
    padding: 12px 24px;
    width: 100%;

    &:hover {
      background-color: $menu-button-hover-bg;
    }

    @include media('<=320px') {
      padding: $gl-spacer-xxs $gl-spacer-xxs;
    }
  }
}

.navbar__menu__item__content {
  align-items: center;
  box-shadow: 0 1px 0 rgba($theme-dark-dkr, 0.04);
  display: flex;
  padding: 16px 0;
  width: 100%;
}

.navbar__menu__item__text {
  display: inline-block;
  flex: 1;
}

.navbar__menu__item__text__title {
  color: $theme-dark-dkr;
  margin: 0;
  font-weight: 600;
  font-size: $gl-font-size-s2;
  line-height: $gl-line-height-sm;

  &--has-description {
    @include media('<=320px') {
      font-size: $gl-font-size-xs;
    }
  }
}

.navbar__menu__item__text__description {
  color: $theme-dark-alt;
  font-size: $gl-font-size-xs;
  line-height: $gl-line-height-sm;
  margin: $gl-spacer-4xs 0 0 0;

  @include media('<=320px') {
    font-size: $gl-font-size-xxs;
  }
}

.navbar__menu__item__icon-right {
  color: rgba($theme-dark-dkr, 0.3);
  flex-shrink: 0;
}

.navbar__menu__label {
  font-size: $gl-font-size-base;
  font-weight: bold;
  line-height: 1.25;
  margin: 0 0 12px 24px;

  @include media('<=320px') {
    font-size: $gl-font-size-s2;
  }
}

// .notification {
//   @include button-reset();

//   align-items: center;
//   color: inherit;
//   display: flex;
//   font-size: $gl-font-size-s;
//   padding: $gl-spacer-s $gl-spacer-m;

//   &:hover {
//     background-color: $menu-button-hover-bg;
//   }

//   @include media('<=320px') {
//     padding: $gl-spacer-xxs $gl-spacer-xxs;
//   }

//   &--unclickable {
//     cursor: default;

//     a:hover {
//       text-decoration: underline;
//     }
//   }

//   &--unread {
//     background-color: #f6fafa;
//   }

//   .notification__content {
//     flex: 1;
//   }

//   .notification__image {
//     @include size(40px);

//     align-items: center;
//     background-color: $theme-blue-dk;
//     border-radius: 50%;
//     color: $theme-light-lt;
//     display: flex;
//     flex-shrink: 0;
//     justify-content: center;
//     margin-right: $gl-spacer-xs;
//   }

//   .notification__image__avatar {
//     height: 100%;
//     width: 100%;
//   }

//   .notification__unread {
//     @include size(8px);

//     align-self: flex-start;
//     background-color: $theme-alert;
//     border-radius: 50%;
//     flex-shrink: 0;
//     margin-left: $gl-spacer-xxs;
//     margin-top: $gl-spacer-xxs;
//   }

//   .notification__date {
//     color: rgba($theme-dark-dkr, 0.54);
//     margin-left: $gl-spacer-3xs;
//   }
// }

.notifications__menu {
  max-height: $dropdown-max-height;
  overflow-y: auto;
}

.side-navigation-overlay {
  background-color: $gl-overlay-bg;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $gl-z-index-max;
}

.side-navigation {
  background-color: $theme-light-lt;
  height: 100%;
  display: none;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 280px;
  z-index: $gl-z-index-max;

  &--active {
    display: flex;
    flex-direction: column;
    left: 0;
  }
}

.side-navigation__container {
  overflow-y: auto;
}

.side-navigation__button-close {
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  margin: 16px 0 5px 24px;
  padding: 0;
  width: 18px;
}

.side-navigation__menu {
  list-style: none;
  margin: 10px 0;
  padding: 0;
}

.side-navigation__menu__item,
.side-navigation__menu__item-group__children__item {
  align-items: center;
  display: flex;
  padding: $gl-spacer-xs $gl-spacer-m;

  &:hover {
    background-color: $menu-button-hover-bg;
  }
}

.side-navigation__menu__item-group__children__item {
  padding-left: 0;
}

.side-navigation__menu__item__text,
.side-navigation__menu__group__text {
  color: $theme-dark-dkr;
  display: inline-block;
  font-size: $gl-font-size-s2;
  font-weight: 600;
  margin: 0;
}

.side-navigation__menu__item-group {
  align-items: center;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: $gl-spacer-xs $gl-spacer-m;
  width: 100%;
}

.side-navigation__menu__group__text {
  flex: 1;
  text-align: left;
}

.side-navigation__menu__item-group__children {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;

  &--active {
    display: block;
  }
}

.side-navigation__menu__item-group__children__item {
  padding: 6px 45px;
}

.side-navigation__menu__item-group__children__item__text {
  color: $theme-dark-dkr;
  display: inline-block;
  margin: 0 0 0 15px;
}

.side-navigation__menu__item-separator {
  border-bottom: 1px solid rgba($theme-dark-dkr, 0.07);
  margin: $gl-spacer-s $gl-spacer-m;
  height: 1px;
}

.user-avatar {
  background-color: $theme-light;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  height: 32px;
  margin-right: 9px;
  overflow: hidden;
  width: 32px;
  text-align: center;

  &--has-error {
    background: none;
  }

  &__icon {
    color: $theme-dark-dkr;
  }
}

$dot-size: 8px;
$dot-color: $theme-dark-lt;
$dot-active-color: $theme-alert;

.dot-wrapper {
  display: inline-block;
  position: relative;
  margin-left: 8px;
  margin-bottom: 1px;
}

.dot {
  background-color: $dot-color;
  @include size($dot-size);
  border-radius: 50%;
  top: 0;
  left: 0;

  &.hide-pulse {
    display: none;
  }
}

.dot-wrapper--active .dot {
  background-color: $dot-active-color;

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @include size($dot-size);
    background-color: $dot-active-color;
    border-radius: 50%;

    animation: size ease-out 1.4s, opacity linear 1.4s;
    animation-iteration-count: infinite;
  }

  &:after {
    animation-delay: 0.35s;
  }
}

@keyframes size {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.75);
  }
  100% {
    transform: scale(1.75);
  }
}

@keyframes opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.5;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

div.add-document-modal {
  @include desktop {
    min-height: 400px;
    width: 608px;

    &__inner {
      padding-left: 104px;
      padding-right: 104px;
    }
  }
}

.menu_new__footer {
  margin-top: $gl-spacer-m;
  padding: $gl-spacer-m $gl-spacer-m $gl-spacer-xs;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.065);
  &__text {
    margin-left: $gl-spacer-s;
    font-size: $gl-font-size-xs;
    line-height: 1.5;
    color: rgba($theme-dark-dkr, 0.69);

  }
}


.icons {
  display: none;
  // margin-left: 10px;

  @include desktop {
    display: flex;
    flex-wrap: nowrap;
    // margin-left: 26px;
  }
}

a.icons__item, span.icons__item {
  width: auto;
  height: 48px;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  color: rgba(0,0,0, 0.54);
  border-radius: 6px;
  padding: 8px 12px;

  &:hover {
    // background: $theme-light;
    color: #018383;

    .icons__tooltip {
      display: block;
      z-index: $gl-z-index-pre-max;
    }
  }

  @include media('>=1140px', '<=1200px') {
    padding: 8px;
  }

  // & + & {
  //   margin-left: $gl-spacer-xxs;
  // }


  &--active {
    background: none;

    // &::after {
    //   content: '';
    //   top: 100%;
    //   left: 0;

    //   margin-top: 2px;
    //   margin-left: 12px;
    //   margin-right: 12px;
    //   display: flex;
    //   width: auto;
    //   margin-right: auto;
    //   min-width: 80%;
    //   height: 2px;
    //   background: $theme-primary;
    //   border-radius: 2px;
    //   position: absolute;
    // }

    span.icons__item__icon {
      color: $theme-primary;
    }
  }


}

.icons__item__inner {
  display: flex;
  width: 100%;
  position: relative;
  color: rgba(0, 0, 0, 0.61);
  @include hover {
    color: #018383;
  }

  div {
    @include hover {
      color: #018383;
    }
  }
}

.icons__item__underline {
  background: $theme-primary;
  border-radius: 100px;
  bottom: -22px;
  height: 2px;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  width: 100%;

  @include mobile {
    bottom: -8px;
  }
}


.icons__tooltip {
  padding: 8px 12px;
  border-radius: 8px;
  background: $theme-dark-dkr;
  font-size: $gl-font-size-xxs;
  position: absolute;
  top: 100%;
  margin-top: 13px;
  color: $theme-light-lt;
  white-space: nowrap;

  left: 50%;
  transform: translate(-50%, 0);
  display: none;
}

// .announcement_tooltip_target {
//   margin-right: $gl-spacer-xs;
//   z-index: $gl-z-index-max;
// }

// div.announcement_tooltip {
//   margin-right: $gl-spacer-xs;
//   transform: unset;
//   background: $theme-light-lt;
//   width: 304px;
// }

.overlay {
  content: '';

  background: rgba($theme-dark-dkr, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // z-index: $gl-z-index-pre-max;
  z-index: $gl-z-index-1;
}

.content {
  position: relative;
  z-index: $gl-z-index-max;
}

.navbar-container--active-desktop-search {
  // border-bottom: 1px solid #535071;
  border-bottom: unset;
}

.nav_search_btn {
  @include mobile {
    display: none;
  }
}


// a__* prefix means animation classes required for react CSSTransition
.a__enter {
  opacity: 0;
  transform: translateY(17%);
}
.a__enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity .3s ease-out, transform .3s ease-out;
}

.a__enter-2 {
  opacity: 0;
  transform: translateY(-17%);
}
.a__enter-active-2 {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity .3s ease-out, transform .4s cubic-bezier(0.311, 0.441, 0.444, 1.649);
}
.a__exit {
  display: none;
}
.a__exit-2 {
  opacity: 0.5;
  transform: translateY(-0%);
}
.a__exit-active-2 {
  opacity: 0;
  transform: translateY(-25%);

  transition:
    opacity .25s ease-out,
    transform .25s ease-out;
}
.a__exit-3 {
  opacity: 0.4;
  transform: translateY(-10%);
}
.a__exit-active-3 {
  opacity: 0;
  transform: translateY(-85%);

  transition:
    opacity .25s ease-out,
    transform .25s ease-out;
}

.banner_back_layer {
  background: linear-gradient(270deg, #5f5a83 0%, #424158 100.35%);
  height: 111px;
  width: 100%;
  position: absolute;

  &--lg {
    height: 151px;
  }
}

.partnership {
  @include desktop {
    width: 514px $important;
  }
}
