
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
/* ==========================================================================
   #TAGS
   ========================================================================== */
$tag-font-size    : rem($gl-font-size-xxs);
$tag-line-height  : $gl-line-height-xs;
$tag-radius       : $gl-radius-base;
$tag-hover-opacity: 0.7;

$tag-variants: (
  primary: (
    background: rgba($theme-primary, 0.1),
    color: $theme-primary
  ),
  alt: (
    background: $theme-light,
    color: $theme-dark
  ),
  accent: (
    background: rgba($theme-primary-alt, 0.1),
    color: $theme-primary-alt
  ),
  cold: (
    background: rgba($clr-lavender, 0.2),
    color: darken($clr-lavender, 23%)
  )
);

.tag {
  @include button-reset();

  display: inline-block;
  border-radius: $tag-radius;
  line-height: $tag-line-height;
  white-space: nowrap;
  cursor: default;

  box-sizing: border-box;
  background: transparent;
  padding: $gl-spacer-3xs $gl-spacer-xxs;
  font-size: $tag-font-size;
  font-weight: 500;
}

.link {
  cursor: pointer;

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
    opacity: $tag-hover-opacity;

    // &--with-close {
    //   display: inline-block;
    // }
  }
}


// Variants
@each $variant, $opts in $tag-variants {
  .#{'' + $variant} {
    @include tag-variant(
      map-get($opts, background),
      map-get($opts, color)
    );
  }
}

// TODO: migrate other styles

// &--is-unclickable {
//   cursor: text;

//   // Share hover and focus styles
//   @include hover-focus {
//     opacity: 1;
//   }
// }

// &--with-close {
//   display: none;
// }

// // Show close icon only on hover
// &--with-close {
//   .#{$icon-class} {
//     display: none;
//   }

//   @include hover-focus {
//     .#{$icon-class} {
//       display: inline-block;
//     }
//   }
// }

