
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
/* ==========================================================================
   #TABS
   ========================================================================== */

// Default Variables

// Border
$tabs-border-color       : $theme-light-dk !default;
$tabs-border-width       : $gl-border-width-base !default;
$tabs-hover-border-color : $theme-primary !default;
// Text
$tabs-color        : $theme-dark !default;
$tabs-font-size    : $gl-font-size-base !default;
$tabs-font-size-sm : $gl-font-size-s !default;
// States
$tabs-active-color   : $theme-dark-dk !default;
$tabs-disabled-color : $theme-dark-ltr !default;
// Spacing
$tabs-spacing-x: 24px !default;
$tabs-padding-y: 12px !default;
$tabs-padding-y-mobile: 8px !default;

// Horizontal tabs navigation

.tabs-list {
  $self: &;

  border-bottom: $tabs-border-width solid transparent;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: $tabs-font-size;

  @include list-unstyled();
}

// Small tabs
.sm {
  font-size: $tabs-font-size-sm;
}

// Tabs with a bottom border
.underlined {
  border-bottom-color: $tabs-border-color;
}

// Tab list item
.item {
  margin: 0;
  padding: 0;
  margin-bottom: - $tabs-border-width;
  position: relative;
  display: inline-block;

  // Spacing between tabs
  & + & {
    margin-left: rem($tabs-spacing-x);
  }

  .tag {
    margin-left: $gl-spacer-xxs;
    display: none;

    @include media('>sm') {
      display: inline-block;
    }
  }


}



.link {
  color: $tabs-color;
  cursor: pointer;

  @include hover-focus {
    color: $tabs-active-color;
    text-decoration: none;
  }

  // Disabled state
  @at-root {
    &.is-active,
    .item.is-active & {
      color: $tabs-active-color;
      font-weight: 500;
      border-bottom-color: $tabs-hover-border-color;
    }

    &.is-disabled,
    .item.is-disabled & {
      @include plain-hover-focus {
        color: $tabs-disabled-color;
        border-bottom-color: transparent;
      }

      cursor: initial;
      pointer-events: none;
    }
  }

  &::after {
    display: block;
    content: attr(data-content);
    font-weight: bold;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }

}

.label {
  color: $tabs-active-color;
}

.link,
.label {
  display: inline-block;
  padding: 0 0 rem($tabs-padding-y) 0;
  border-bottom: 2 * $tabs-border-width solid transparent;

  @include media('<sm') {
    padding: 0 0 rem($tabs-padding-y-mobile) 0;
  }

  @at-root {
    .sm > & {
      padding-bottom: $gl-spacer-3xs;
    }
  }
}

// Full width tabs: tab elements will automatically layout as equal width columns
.full {
  // reset spacing between tabs
  .item {
    flex: 1;
  }

  .link,
  .label {
    width: 100%;
    text-align: center;
    display: block;
  }

  .item + .item {
    margin-left: 0;
  }
}
