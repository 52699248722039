
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    

.container {
  display: block;
  margin: 0;
  // border: none;
  // background: none;

  .input {
    border: none;
    background: none;

    &__field {
      padding: 10px 8px;
      height: 48px;
      font-weight: 400;
      font-size: $gl-font-size-base;
      border-radius: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
    }


  }

  .field-wrapper {
    display: block;
    position: relative;
    // overflow: hidden;
    // border-radius: 100px;
  }
}

.inner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


// Dropdown Item


div.menu{
  .dd {
    &__item {
      padding: 0;

      & + & {
        margin-top: 6px;
      }
    }
  }
}

.footer {
  display: flex;

}
