
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.wrapper {
  height: 100%;
}

.container {
  line-height: 1;
  display: block;
  border-radius: 100px;
  height: 100%;

  .input {
    border: none;
    background: none;

    &__field {
      padding: (17px + $gl-spacer-xs) $gl-spacer-l $gl-spacer-xs;
      height: auto;
      font-weight: 500;
      font-size: $gl-font-size-s2;
      border-radius: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: $gl-line-height-base;

      @include mobile {
        padding: 13px;
        // font-size: $gl-font-size-s;
        font-size: $gl-font-size-base;
      }
    }
  }

  .field-wrapper {
    display: block;
    position: relative;
    // overflow: hidden;
    // border-radius: 100px;
  }
}

@include desktop {
  .selectable:hover {
    background-color: #f5f5f5;
  }

  .active {
    background: $theme-light-lt;
    box-shadow: 0px 14px 34px 4px rgba(0, 0, 0, 0.16);
    position: relative;
    z-index: $gl-z-index-1;
  }

}

.label {
  font-size: $gl-font-size-xxs;
  color: $clr-dark-font;
  font-weight: 600;
  position: absolute;
  left: $gl-spacer-l;
  top: $gl-spacer-xs;
  // z-index: $gl-z-index-pre-max;
  line-height: normal;

  @include mobile {
    display: none;
  }
}

.inner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.placeholder {

}


@include desktop {
  .container {

  }
}


// Dropdown Item

div.menu{
  // border: 1px solid rgba($theme-dark-dkr, 0.065);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 20px;

  .dd {
    &__item {
      padding: 0;

      & + & {
        margin-top: 6px;
      }
    }
  }
}


.select {
  &__input {
    padding: 29px 32px 12px;
    height: auto;
    font-weight: 500;
    font-size: 15px;
    border-radius: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5;
    border: none;
    background: none;
  }

  &__wrapper {
    border: none;
    background: none;
  }
}
