
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
///* ========================================================================
//   #Toolbar
//   ======================================================================== */

// A fixed toolbar with dropdowns

// Container
$navbar-item-size: 56px;
$navbar-bg: $theme-light-lt;
$navbar-color: $theme-dark-dk;
$navbar-z-index: $gl-z-index-pre-max;
$navbar-padding-x: $gl-spacer-s;
$navbar-padding-y: $gl-spacer-s;
$navbar-overlay-bg: $gl-overlay-bg;
$navbar-inactive-opacity: 0.6;
// Dropdown
$navbar-dd-padding-x: $gl-spacer-m;
$navbar-dd-padding-y: $gl-spacer-m;
$navbar-dd-item-hover: $theme-light;
$navbar-dd-section-spacing: 40px;
// Separator
$navbar-separator-color: $theme-light-dk;
$navbar-separator-size: 1px;

// Label
$navbar-label-font-size: 10px;
$navbar-label-color: $theme-dark-dkr;
$navbar-spacing: 2px; // spacing between the icon and the label

// for override default styles
$important: config(important);

.container {

  // TODO: HOTFIX. We need to move mobile toolbar
  // to the bottom after the footer
  // position: absolute;
  // z-index: $navbar-z-index;
  // bottom: 0;
  // left: 0;
  // min-height: $navbar-item-size;

}

.inner {
  // position: fixed;
  // left: 0;
  // bottom: 0;
  // right: 0;
  padding: 0;
  background-color: $navbar-bg;
  color: $navbar-color;
  border-top: $navbar-separator-size solid $navbar-separator-color;

  .round {
    align-items: center;
    background: $theme-dark-dk;
    border-radius: 16px;
    color: $theme-light-lt;
    display: inline-flex;
    padding: 6px 14px;
    justify-content: center;

    svg {
      height: 20px;
      width: 20px;
    }
  }

}

.row {
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;

  // Adjust logo size so it appears the same size as the other icons
  &:first-of-type {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  div.search_item {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div.search_item_toggle {
    // TODO: check toggle height
    height: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span.search_item_icon {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
  }
}

.link {
  @include button-reset();

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: $navbar-item-size;
  max-width: $navbar-item-size;
  width: 100%;
  position: relative;

  @include customLink($navbar-label-color, $navbar-color);

  @at-root {
    .item:first-child & {
      color: $navbar-color;
    }
  }

  svg,
  .label {
    opacity: $navbar-inactive-opacity;
    filter: grayscale(100%);
  }

  &:hover,
  &:focus,
  &.is-active {

    .label {
      font-weight: 600;
    }

    svg,
    .label {
      opacity: 1;
      filter: inherit;
    }
  }
}


.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $navbar-overlay-bg;
}

.label {
  font-size: $navbar-label-font-size;
  font-weight: normal;
  width: 100%;
  text-align: center;
  line-height: $gl-line-height-xs;
}

.count {
  z-index: $gl-z-index-1;
}

// DROPDOWN MENU
.dd {
  position: fixed;
  left: 0;
  right: 0;
  text-align: left;
  bottom: 0;
  background: $navbar-bg;
  border-radius: 32px 32px 0 0;
  padding-top: rem($navbar-dd-padding-y);
  // The dropdown's height must be no more than {the window's height - the bar's height}
  max-height: calc(100% - #{$navbar-item-size});
  overflow: auto;

  .dd__close-container {
    margin-bottom: $gl-spacer-xs;
    padding-bottom: $gl-spacer-xs;
  }


  .dd__close {
    border-radius: 16px;
    display: block;
    height: 32px;
    margin: 0 auto;
    padding: 0;
    width: 48px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.25;
    margin: $gl-spacer-s 0;
    text-align: center;
  }

  &__section {
    @include list-unstyled();
    // Spacing between sections
    & + & {
      margin-top: rem(math.div($navbar-dd-section-spacing, 2) - $navbar-separator-size);
      padding-top: rem(math.div($navbar-dd-section-spacing, 2) - $navbar-separator-size);
      border-top: $navbar-separator-size solid $navbar-separator-color;
    }
  }

  &__item {
    &__icon {
      color: $theme-primary;
      margin-right: $gl-spacer-s;
    }

    &__text {
      display: inline-block;
      flex: 1;
    }

    &__text__title {
      color: $theme-dark-dkr;
      margin: 0;
      font-weight: 500;
      font-size: $gl-font-size-s2;
      line-height: $gl-line-height-sm;
    }

    &__text__description {
      color: $theme-dark-alt;
      font-size: $gl-font-size-xs;
      line-height: $gl-line-height-sm;
      margin: $gl-spacer-4xs 0 0 0;
    }
  }

  &__link {
    @include button-reset();

    align-items: center;
    display: flex;
    padding: 12px 24px;
    color: $navbar-color;
    position: relative;
    width: 100%;

    &:hover,
    &:focus,
    &.is-active {
      text-decoration: none;
      background: $navbar-dd-item-hover;
      color: $navbar-color;
    }
  }


  &__count {
    position: absolute;
    top: 50%;
    left: math.div(rem($navbar-dd-padding-x), 2);
    transform: translate(0, -50%);
  }
}

.icon {
  color: #000000;
  display: inline-flex;
  justify-content: center;
  position: relative;

  &:not(.round) {
    height: 22px;
    width: 22px;
    align-items: flex-end;
    margin-top: -$navbar-spacing;
    margin-bottom: $navbar-spacing;
  }

  &.round {
    color: #ffffff;
    align-items: center;
  }
}

// Hide the bottom toolbar, when the mobile keyboard is visible
// @include media("height<350px", "portrait") {
//   display: none;
// }

.bottom-navbar-login {
  bottom: 0;
  padding-bottom: $gl-spacer-l;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  width: 100%;
  z-index: $gl-z-index-pre-max - 1;

  @include make-container;
  @include make-container-max-widths;

  @include media('>=md') {
    display: none;
  }

  @include phone {
    padding-left: $gl-spacer-m;
    padding-right: $gl-spacer-m;
  }
}
